form {
    span.error {
        display: block;
    }

    .invalid {
        label:not(:has(input)),
        span.error {
            color: #c00 !important;
        }
    }
}